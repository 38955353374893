import * as R from 'ramda'
import * as React from 'react'
import LazyLoad from 'react-lazyload'

import * as common from '@rushplay/common'
import { useI18n } from '@rushplay/i18n'

export function InformativeSection() {
  const i18n = useI18n()

  return (
    <common.Box
      display="flex"
      flexWrap="wrap"
      width="100%"
      justifyContent="space-around"
    >
      {R.map(
        item => (
          <common.Box
            key={item}
            display="flex"
            flexDirection="column"
            color="g-text"
            alignItems="center"
            flexBasis="0"
            flexGrow="1"
            flexShrink="1"
            maxWidth="280px"
            minWidth="200px"
            m={2}
            textAlign="center"
            lineHeight="1.25"
          >
            <LazyLoad once offset={100} height="75px">
              <img
                src={i18n.translate(`informative.no${item}.icon`)}
                alt={item}
                height="75"
              />
            </LazyLoad>
            <common.Box
              fontWeight="bold"
              py={1}
              fontSize={4}
              lineHeight="1.5"
              as="h3"
              fontFamily="head"
            >
              {i18n.translate(`informative.no${item}.title`)}
            </common.Box>
            <common.Box
              opacity="0.8"
              fontSize={2}
              as="p"
              lineHeight="1.8"
              textAlign="left"
            >
              {i18n.translate(`informative.no${item}.description`)}
            </common.Box>
          </common.Box>
        ),
        [1, 2, 3, 4]
      )}
    </common.Box>
  )
}
